
const ApiConst = {
    AdminLogin: '/markapi/admin/login',    // 管理员登录
    AdminInfo: '/markapi/admin/info',       //当前管理员信息

    VideoList: '/markapi/video/list',       // 视频信息列表
    VideoInfo: '/markapi/video/info',
    VideoCreate: '/markapi/video/create',
    AudioPartList: '/markapi/audio/part_list', // 片段列表
    CombinePart: '/markapi/audio/combine_part',
    DeprecatedPart: '/markapi/audio/deprecated',
    Annotate: '/markapi/audio/annotate',
    CustomQA: '/markapi/audio/custom_qa',   // 添加自定义对话

    // tts相关
    TTSSpeakerList: '/markapi/role/speaker_list',
    TTSSpeakerCreate: '/markapi/role/speaker_create',
    TTSSpeakerCost: '/markapi/role/speaker_cost',

    // 角色相关
    RoleList: '/markapi/role/role_list',
    RoleSet: '/markapi/role/role_set',
    RobotRole: '/markapi/role/robot_role',
    RobotList: '/markapi/role/robot_list',

    // 小程序 demo
    DemoRoleList: '/markapi/mini/role_list',
    DemoRoleSet: '/markapi/mini/set_role',

    GetStsToken: '/markapi/admin/sts_token',    // 获取aliyun的sts token

    // web demo
    WebDemoRole: '/api/webdemo/role',
    WebDemoChat: '/api/webdemo/chat',
    WebDemoChatHistory: '/api/webdemo/get_chat_history',

    // dipper demo
    DipperChat: '/markapi/demo/dipper_chat',

    // web demo manage
    WebDemoChatSearch: '/markapi/mini/search_chat_history',

    // local chat
    LocalChatSet: '/markapi/chat/set_local_chat',
    LocalChatTest: '/markapi/chat/local_chat_test',
    LocalChatList: '/markapi/chat/local_chat_list',

    // 聊天记录
    DidChatHistory: '/markapi/chat/chat_history',
    DeleteChatHistory: '/markapi/chat/delete_chat',

    // 乙游聊天记录
    OtomeChatHistory: '/markapi/vrole/otome/chat_history',
    OtomeDeleteChat: '/markapi/vrole/otome/delete_chat',

    // AI幻觉相关
    IllusionSet: '/markapi/chat/create_illusion',
    IllusionSearch: '/markapi/chat/check_illusion',

    // 聊天情绪
    ChatEmoSet: '/markapi/common/emotion/upsert',
    ChatEmoDel: '/markapi/common/emotion/delete',
    ChatEmoSearch: '/markapi/common/emotion/search',

    // 通用分类
    ClusterCreate: '/markapi/common/cluster/upsert',
    ClusterSearch: '/markapi/common/cluster/search',

    // nb doll firmware
    FirmwareList: '/markapi/nb/fw_list',
    FirmwareCreate: '/markapi/nb/fw_create',
    FirmwareUpdate: '/markapi/nb/fw_update',
    FirmwareStatus: '/markapi/nb/fw_status',
    FirmwareDelete: '/markapi/nb/fw_delete',

    // app version
    AppVersionList: '/markapi/nb/app_version_list',
    AppVersionCreate: '/markapi/nb/app_version_create',
    AppVersionUpdate: '/markapi/nb/app_version_update',
    AppVersionStatus: '/markapi/nb/app_version_status',
    AppVersionDelete: '/markapi/nb/app_version_delete',

    // whitelist
    WhiteList: '/markapi/nb/white_list',
    WhiteList_Set_Info: '/markapi/nb/wl_set_info',
    WhiteList_Set_List: '/markapi/nb/wl_set_list',

    // nb doll online
    DollStatus: '/markapi/nb/device_status',
    DollCmd: '/markapi/nb/device_cmd',

    // nbdoll tts相关
    NBTTSSet: '/markapi/nb/tts_set',
    NBTTSList: '/markapi/nb/tts_list',
    DoTTS: '/markapi/nb/do_tts',
    XilabVoiceList: '/markapi/nb/xilab_voice_list',

    // nbdoll 角色相关
    NBRoleList: '/markapi/nb/role_list',
    NBRoleSet: '/markapi/nb/role_set',
    CharacterList: '/markapi/nb/character_list',
    CharacterSet: '/markapi/nb/character_set',

    WebCharacterList: '/webapi/demo/character_list',
    WebCharacterVoice: '/webapi/demo/character_voice_url',

    // nbdoll 设备相关
    NBDollInfo: '/markapi/nb/device_info',
    NBDollCreate: '/markapi/nb/create_device',
    NBDollChangeRole: '/markapi/nb/device_change_role',
    NBDollRemark: '/markapi/nb/device_remark',

    // nbdoll 设备生产相关
    DeviceProduceList: '/markapi/manu/produce_device_list',
    GenDevices: '/markapi/manu/gen_devices',
    GenProduceCode: '/markapi/manu/gen_produce_code',

    ProduceBatchList: '/markapi/manu/produce_batch_list',
    CreateProduceBatch: '/markapi/manu/create_produce_batch',

    // nbdoll 聊天控制相关
    PromptTemplateSet: '/markapi/nb/prompt_create',
    PromptTemplateList: '/markapi/nb/prompt_list',
    ChatWithTemplate: '/markapi/nb/chat_with_template',

    // nbdoll 娇喘声音管理相关
    PantingSeriesCreate: '/markapi/nb/panting_series_create',
    PantingSeriesList: '/markapi/nb/panting_series_list',
    PantingVoiceSet: '/markapi/nb/panting_voice_set',
    PantingVoiceList: '/markapi/nb/panting_voice_list',

    PantingKeySet: '/markapi/nb/voice_key_set',
    PantingKeyList: '/markapi/nb/voice_key_list',
    PantingMapSet: '/markapi/nb/voice_map_set',
    PantingMapList: '/markapi/nb/voice_map_list',
    PantingMapSearch: '/markapi/nb/voice_map_search',

    // 对话库相关
    DialogList: '/markapi/nb/dialog_list',
    DialogDetail: '/markapi/nb/dialog_detail',
    DialogSet: '/markapi/nb/dialog_set',
    DialogTest: '/markapi/nb/dialog_test',

    // banner 相关
    BannerList: '/markapi/nb/banner_list',
    BannerSet: '/markapi/nb/banner_set',
    BannerDelete: '/markapi/nb/banner_delete',

    DraftList: '/markapi/nb/draft_list',
    DraftUpload: '/markapi/nb/draft_upload',
    DraftCopy: '/markapi/nb/draft_copy',
    DraftPick: '/markapi/nb/draft_pick',
    DraftDrop: '/markapi/nb/draft_drop',

    // 通用QA相关
    CommonQAList: '/markapi/common/qa/list',
    CommonQADetail: '/markapi/common/qa/detail',
    CommonQASet: '/markapi/common/qa/set',
    CommonQADelete: '/markapi/common/qa/delete',
    CommonQATest: '/markapi/common/qa/test',

    // 通用前缀查询相关
    SWList: '/markapi/chat/sw_list',
    SWCreate: '/markapi/chat/sw_create',
    SWDelete: '/markapi/chat/sw_del',
    SWCheck: '/markapi/chat/sw_check',

    // 默认回复相关
    SWReplyList: '/markapi/chat/sw_reply_list',
    SWReplySet: '/markapi/chat/sw_reply_set',
    SWReplyDelete: '/markapi/chat/sw_reply_del',

    CommonVideoUpload: '/markapi/common/video/upload',
    CommonVideoInfo: '/markapi/common/video/info',

    CommonFileUpload: '/markapi/common/file/upload',
    CommonFileList: '/markapi/common/file/list',
    CommonFileDelete: '/markapi/common/file/delete',

    // 插件相关
    VideoPluginList: '/markapi/common/plugin/video_list',
    VideoPluginUpsert: '/markapi/common/plugin/video_upsert',
    CmdPluginList: '/markapi/common/plugin/cmd_list',
    CmdPluginUpsert: '/markapi/common/plugin/cmd_upsert',
    PluginProcess: '/markapi/common/plugin/process',

    // INS相关
    InsEventSet: '/markapi/common/ins/event_set',
    InsEventList: '/markapi/common/ins/event_list',
    InsEventDelete: '/markapi/common/ins/event_delete',
    InsSkinLogParse: '/markapi/common/ins/skin_log_parse',

    // 电子皮肤数据相关
    PressDataList: '/markapi/common/ins/press_data_list',

    // 唤醒词相关
    WakeWordSet: '/markapi/common/wake_word/set',
    WakeWordList: '/markapi/common/wake_word/list',
    WakeWordDelete: '/markapi/common/wake_word/delete',

    // 特效相关
    EffectSet: '/markapi/vrole/effect/set',
    EffectList: '/markapi/vrole/effect/list',
    EffectDelete: '/markapi/vrole/effect/delete',

    EffectMarkSet: '/markapi/vrole/effect_mark/set',
    EffectMarkList: '/markapi/vrole/effect_mark/list',
    EffectMarkDelete: '/markapi/vrole/effect_mark/delete',
    EffectMarkCheck: '/markapi/vrole/effect_mark/check',

    // 视频手办角色 相关
    VRoleSet: '/markapi/vrole/vrole/set',
    VRoleList: '/markapi/vrole/vrole/list',
    VRoleDetail: '/markapi/vrole/vrole/detail',
    VRoleDelete: '/markapi/vrole/vrole/delete',

    RoleEffectSet: '/markapi/vrole/role_effect/set',
    RoleEffectList: '/markapi/vrole/role_effect/list',
    RoleEffectDelete: '/markapi/vrole/role_effect/delete',

    PresetPlaySet: '/markapi/vrole/preset/set',
    PresetPlayList: '/markapi/vrole/preset/list',
    PresetPlayDelete: '/markapi/vrole/preset/delete',

    RoleSkinList: '/markapi/vrole/role_skin/list',
    RoleSkinSet: '/markapi/vrole/role_skin/set',
    RoleSkinDelete: '/markapi/vrole/role_skin/delete',

    // AI工具相关
    AiToolList: '/markapi/ai/ai_tool_list',
    AiToolSet: '/markapi/ai/ai_tool_set',
    AiToolDelete: '/markapi/ai/ai_tool_delete',
    AiToolProcess: '/markapi/ai/ai_tool_process',

    // SpicyChat
    SpicyAccountList: '/markapi/nb/spicy_account_list',
    SpicyAccountSet: '/markapi/nb/spicy_account_set',
    SpicyAccountRefresh: '/markapi/nb/spicy_account_refresh',

    // 用户画像
    PortraitGet: '/markapi/chat/get_user_portrait',
    PortraitProcess: '/markapi/chat/process_user_portrait',
    PortraitClear: '/markapi/chat/clear_user_portrait',

    // otome充值卡
    OtomeCardList: '/markapi/vrole/otome/charge_card_list',
    OtomeCardBatchCreate: '/markapi/vrole/otome/batch_create_card',
}

export default ApiConst