<template>
    <el-menu
            :default-active="activeIndex"
            class="el-menu-demo"
            background-color="var(--scheme-color9)"
            text-color="#fff"
            active-text-color="var(--scheme-color2)"
            mode="horizontal"
            :ellipsis="false"
            @select="handleSelect"
    >
        <el-menu-item index="0" class="logo">Soulexe</el-menu-item>
        <div class="flex-grow" />
        <el-sub-menu :index="item.index" v-for="item in menuList" :key="item.index">
            <template #title>
                <span>{{ item.title }}</span>
            </template>
            <el-menu-item :index="subItem.index" v-for="subItem in item.children" :key="subItem.index">
                {{ subItem.title }}
            </el-menu-item>
        </el-sub-menu>
    </el-menu>
</template>

<script>
import Service from "@/util/request";
import ApiConst from "@/util/apiConst";
import router from '@/router'

export default {
    name: "HeaderBar",
    components: {},
    data() {
        return {
            activeIndex: 1,
            //用户个人信息
            user: {
                name: ''
            },
            menuList:[]
        };
    },
    created() {
        this.getAdminInfo();
    },
    methods: {
        getAdminInfo(){
            let _this = this;
            Service.request(ApiConst.AdminInfo, {}, function(data){
                _this.user.name = data.name;
                _this.initMenu();
            });
        },
        initMenu(){
            this.menuList = [
                {"index": "5", "title": "nbdoll", "children": [
                        // {"index": "5-0", "title": "固件版本管理", "link":"/nbdoll/fw"},
                        {"index": "5-0", "title": "批次生产", "link":"/nbdoll/manu/batch_produce"},
                        {"index": "5-1", "title": "设备生产", "link":"/nbdoll/manu/produce"},
                        {"index": "5-2", "title": "APP版本管理", "link":"/nbdoll/app"},
                        {"index": "5-3", "title": "白名单管理", "link":"/nbdoll/whitelist"},
                        {"index": "5-4", "title": "TTS管理", "link":"/nbdoll/ttslist"},
                        {"index": "5-5", "title": "角色管理", "link":"/nbdoll/rolelist"},
                        {"index": "5-6", "title": "设备查询", "link":"/nbdoll/nbdoll"},
                        {"index": "5-7", "title": "SpicyChat账户", "link":"/nbdoll/spicy"},
                        // {"index": "5-7", "title": "demo角色管理", "link":"/nbdoll/character"},
                        {"index": "5-8", "title": "banner管理", "link":"/nbdoll/banner"},
                    ]
                },
                {"index": "6", "title": "对话干预", "children": [
                        {"index": "6-0", "title": "通用QA", "link":"/common/qa"},
                        {"index": "6-1", "title": "对话情绪", "link":"/chat/emotion"},
                        {"index": "6-2", "title": "通用分类", "link":"/chat/cluster"},
                        {"index": "6-3", "title": "娇喘声音配置", "link":"/nbdoll/chat/panting"},
                        {"index": "6-10", "title": "娇喘声音匹配", "link":"/nbdoll/chat/panting_map"},
                        {"index": "6-4", "title": "prompt模板", "link":"/nbdoll/chat/prompt"},
                        {"index": "6-5", "title": "语音链路测试", "link":"/page_ws"},
                        {"index": "6-6", "title": "前缀搜索", "link":"/common/sw"},
                        {"index": "6-7", "title": "默认回复", "link":"/common/sw_reply"},
                        {"index": "6-8", "title": "唤醒词管理", "link":"/common/wake_word"},
                        {"index": "6-9", "title": "乙游对话记录", "link":"/vrole/otome_chat"},
                        {"index": "6-11", "title": "用户对话记录", "link":"/chat/chat_history"},
                    ]
                },
                {"index": "3", "title": "视频手办", "children": [
                        {"index": "3-0", "title": "角色管理", "link":"/vrole/vrole"},
                        {"index": "3-1", "title": "特效管理", "link":"/vrole/effect"},
                        {"index": "3-2", "title": "乙游充值卡", "link":"/vrole/otome_card"}
                    ]
                },
                {"index": "2", "title": "插件管理", "children": [
                        {"index": "2-1", "title": "视频插件", "link":"/plugin/video"},
                        {"index": "2-2", "title": "通用命令插件", "link":"/plugin/cmd"},
                    ]
                },
                {"index": "8", "title": "具身智能", "children": [
                        {"index": "8-1", "title": "INS事件管理", "link":"/common/ins_event"},
                        {"index": "8-2", "title": "电子皮肤数据", "link":"/common/press_data"},
                    ]
                },
                {"index": "1", "title": "小工具", "children": [
                        {"index": "1-1", "title": "文件上传", "link":"/common/upload"},
                        {"index": "1-2", "title": "AI工具", "link":"/common/ai_tool"}
                    ]
                },
                {"index": "9", "title": this.user.name, "children": [
                        {"index": "9-1", "title": "退出登录", "link":"/logout"},
                    ]
                }
            ];
        },
        handleSelect(key, keyPath){
            if (key === "0") {
                router.push("/").then();
                return;
            }
            for(let i in this.menuList){
                if (this.menuList[i]["index"] === keyPath[0]) {
                    if (keyPath.length === 1){
                        if ("link" in this.menuList[i]){
                            router.push(this.menuList[i]["link"]).then();
                            return;
                        }
                    }
                    for(let j in this.menuList[i]["children"]){
                        if (this.menuList[i]["children"][j]["index"] === key){
                            if ("link" in this.menuList[i]["children"][j]){
                                if (this.menuList[i]["children"][j]["link"] === "/logout"){
                                    location.href = "/markapi/admin/logout";
                                } else {
                                    router.push(this.menuList[i]["children"][j]["link"]).then();
                                }
                                return;
                            }
                        }
                    }
                }
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.logo {
  font-weight: bold;
}
.flex-grow {
  flex-grow: 1;
}
</style>
